<template>
  <div class="details" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="classification">
      <div class="item" :class="{ frame: num == 1 }" @click="cliok(1)">
        全部
      </div>

      <div class="item" :class="{ frame: num == 2 }" @click="cliok(2)">
        未支付
      </div>

      <div class="item" :class="{ frame: num == 3 }" @click="cliok(3)">
        已支付
      </div>
    </div>
    <div class="orderform" v-for="(item, index) in list" :key="index">
      <div class="Recharge-zf" v-if="item.order_status == 2">
        <div class="amount">充值金额：{{ item.order_amount }}元</div>

        <div class="time">
          <img src="../../assets/time.png" alt="" />
        </div>

        <div class="times">{{ item.order_time }}</div>

        <div class="weixin">
          <img src="../../assets/weixin.png" alt="" />
        </div>
        <span class="wx">微信支付</span>
        <div class="Paid">已支付</div>
      </div>

      <div class="Recharge-wzf" v-if="item.order_status == 1">
        <div class="amount">充值金额：{{ item.order_amount }}元</div>

        <div class="time">
          <img src="../../assets/time.png" alt="" />
        </div>

        <div class="times">{{ item.order_time.substr(0, 10) }}</div>

        <div class="weixin">
          <img src="../../assets/weixin.png" alt="" />
        </div>
        <span class="wx">微信支付</span>
        <div class="Paid">未支付</div>

        <div class="xian"></div>

        <div @click="gopay(item)" class="Topay"></div>
      </div>
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <div class="nomsgclass"></div>
  </div>
</template>

<script>
import loading from "../../components/loading2/index";
import base from "../../../util/base";
export default {
  components: {
    loading,
  },
  data() {
    return {
      msg: "充值记录",
      num: 1,
      list: [],
      pageno: 0,
    };
  },
  created() {},
  methods: {
    loaddom() {
      //组件加载完成之后默认调用该函数
      this.getmsg({
        reqType: "order",
        type: this.num,
        page: ++this.pageno,
      });
    },
    getmsg(msg) {
      this.$refs.loades
        .loadhttpes("/firm/v1/order/orderList", msg)
        .then((res) => {
          this.list = res.arraymsg;
          this.list.forEach((item) => {
            item.order_time = item.order_time.substr(0, 10);
          });
        });
    },
    gopay(item) {
      this.$paymeth({
        money: item.order_amount,
        orderType: item.order_type,
        id: item.id,
        isdetailspay: "true",
        order_no: item.order_no,
        url: base.url + "/details",
      });
    },
    // 分类请求
    cliok(num) {
      this.num = num;
      this.pageno = 0;
      if (num == 1) {
        this.getmsg({
          reqType: "order",
          type: "1",
          page: ++this.pageno,
        });
      }
      if (num == 2) {
        this.getmsg({
          reqType: "order",
          type: "2",
          page: ++this.pageno,
        });
      }
      if (num == 3) {
        this.getmsg({
          reqType: "order",
          type: "3",
          page: ++this.pageno,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nomsgclass {
  height: 0.3rem;
}
.orderform {
  padding: 0 0.3rem;
}
.classification {
  display: flex;
  margin-bottom: 0.29rem;
  width: 100%;
  height: 0.88rem;
  background: #ffffff;
  .item {
    flex: 1;
    font-weight: 500;
    color: #9f9f9f;
    font-size: 0.32rem;
    text-align: center;
    line-height: 0.88rem;
  }
}

.frame {
  background-image: url("../../assets/frame.png");
  background-size: 28%;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: #f55613 !important;
}
.Recharge-zf {
  margin: 0 auto;
  position: relative;
  margin-top: 0.15rem;
  width: 100%;
  height: 1.66rem;
  background: #ffffff;
  border-radius: 0.16rem;
  overflow: hidden;
  .amount {
    position: absolute;
    left: 0.3rem;
    top: 0.38rem;
    width: 4rem;
    height: 0.34rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;
    line-height: 0.34rem;
  }
  .time {
    position: absolute;
    top: 0.46rem;
    // left: 4.9rem;
    left: 4.93rem;
    // margin-right: 2rem;
    // margin-top: 0.4rem;
    width: 0.21rem;
    height: 0.21rem;
    img {
      vertical-align: top;

      width: 100%;
      height: 100%;
    }
  }
  .times {
    position: absolute;
    left: 5.26rem;
    top: 0.41rem;
    // width: 1.35rem;
    height: 0.32rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #616b80;
    line-height: 0.32rem;
  }
  .weixin {
    position: absolute;
    top: 0.94rem;
    left: 0.3rem;
    width: 0.3rem;
    height: 0.3rem;
    img {
      vertical-align: top;

      width: 100%;
      height: 100%;
    }
  }
  .wx {
    position: absolute;
    left: 0.78rem;
    top: 0.92rem;
    // width: 1.08rem;
    height: 0.35rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.35rem;
  }
  .Paid {
    position: absolute;
    left: 5.89rem;
    top: 1rem;
    color: #088ffa;
    // width: 0.73rem;
    height: 0.16rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #088ffa;
    line-height: 0.16rem;
  }
}
.Recharge-wzf {
  position: relative;
  margin: 0 auto;
  margin-top: 0.15rem;
  width: 100%;
  height: 2.46rem;
  background: #ffffff;
  border-radius: 0.16rem;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  .amount {
    position: absolute;
    left: 0.3rem;
    top: 0.38rem;
    width: 4rem;
    height: 0.34rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;
    line-height: 0.34rem;
  }
  .time {
    position: absolute;
    top: 0.46rem;
    // left: 4.9rem;
    right: 1.77rem;
    // margin-right: 2rem;
    // margin-top: 0.4rem;
    width: 0.21rem;
    height: 0.21rem;
    img {
      vertical-align: top;

      width: 100%;
      height: 100%;
    }
  }
  .times {
    position: absolute;
    left: 5.26rem;
    top: 0.41rem;
    // width: 1.45rem;
    height: 0.32rem;
    font-size: 0.25rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #616b80;
    line-height: 0.32rem;
  }
  .weixin {
    position: absolute;
    top: 0.94rem;
    left: 0.3rem;
    width: 0.3rem;
    height: 0.3rem;
    img {
      vertical-align: top;
      width: 100%;
      height: 100%;
    }
  }
  .wx {
    position: absolute;
    left: 0.78rem;
    top: 0.92rem;
    // width: 1.08rem;
    height: 0.35rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.35rem;
  }
  .Paid {
    position: absolute;
    left: 5.89rem;
    top: 1rem;
    color: #088ffa;
    // width: 0.73rem;
    height: 0.16rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #088ffa;
    line-height: 0.16rem;
  }
  .xian {
    position: absolute;
    left: 0.3rem;
    top: 1.45rem;
    height: 0.02rem;
    width: 6.3rem;
    background-color: #dddddd;
  }
  .Topay {
    position: absolute;
    left: 5.52rem;
    top: 1.77rem;
    width: 1.08rem;
    height: 0.43rem;
    background: #ffffff;
    border-radius: 0.05rem;
    background-image: url("../../assets/recharge.png");
    background-size: 100%;
  }
}
</style>
